<template>
  <p />
</template>

<script >
export default {
  setup() {
    window.parent.postMessage("credas:initialized", window.location.origin);
  },
};
</script>

<style>
</style>